// 引入vuerouter的相关包
import { createWebHashHistory, createRouter } from "vue-router";

// 创建实例化对象
const history = createWebHashHistory();
const router = createRouter({
  history,
  routes: [
    {
      name: "登录注册",
      path: "/login",
      component: () => import("@/views/LoginView/LoginView"),
    },
    {
      name: "主体",
      path: "/main",
      redirect: "/home",
      component: () => import("@/views/MainView/MainView"),
      children: [
        {
          name: "首页",
          path: "/home",
          component: () => import("@/views/HomeView/HomeView"),
        },
        {
          name: "应用方案",
          path: "/produkte",
          component: () => import("@/views/ProdukteView/ProdukteView"),
        },
        {
          name: "数字开发",
          path: "/solutions",
          component: () => import("@/views/SolutionsView/SolutionsView"),
        },
        {
          name: "关于我们",
          path: "/about",
          component: () => import("@/views/AboutView/AboutView"),
        },
      ],
    },
  ],
});

// 把router路径暴露出去
export default router;
